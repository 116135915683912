.enter-num-inp {
    width: 100%;
    height: 50px;
    background: #EDF2F7;
    border-radius: 5px;
    border: none;
    padding: 0.375rem 1.75rem;
    margin-bottom: 10px;
  }
  
  .enter-num-inp::-webkit-outer-spin-button,
  .enter-num-inp::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  .enter-num-inp[type=number] {
    -moz-appearance: textfield;
  }
  