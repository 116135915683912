body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
tr.expandable > td {
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, .2);
  padding: 0;
}

tr.expandable > td > .inner {
  margin: 15px;
  overflow: hidden;
}
@media (min-width: 765px) and (max-width: 1248px) {
  .inner__content {
    margin-left: 250px !important;
    margin-right: 220px !important;
    padding: 12px !important;
  }

 
 
  .left-title-box {
    margin-left: 50px;
  }
  .Enter .left-title {
    font-size: 50px;
  }
  .Enter .left-title-second {
    font-size: 50px;
  }
  .Enter .left-title-third {
    font-size: 50px;
  }
  .Registration .left-title {
    font-size: 50px;
  }
  .Registration .left-title-second {
    font-size: 50px;
  }
  .Registration .left-title-third {
    font-size: 50px;
  }
  .Verification .left-title {
    font-size: 50px;
  }
  .Verification .left-title-second {
    font-size: 50px;
  }
  .Verification .left-title-third {
    font-size: 50px;
  }
  .ForgotPassword .left-title {
    font-size: 50px;
  }
  .ForgotPassword .left-title-second {
    font-size: 50px;
  }
  .ForgotPassword .left-title-third {
    font-size: 50px;
  }
  .NewPassword .left-title {
    font-size: 50px;
  }
  .NewPassword .left-title-second {
    font-size: 50px;
  }
  .NewPassword .left-title-third {
    font-size: 50px;
  }

  .Bank-info-main .right-bar {
    right: -15px !important;
  }

  .Bank-info-main .main-payment-receivers-container {
    display: block !important;
  }
  .verification-form .send-btn {
    width: 300px;
  }

 
}

@media (min-width: 320px) and (max-width: 760px) {
  .pb-5 {
    padding-top: 3rem!important;
}
  .bm-burger-button{
    display:block ;
    top:16px;
  }
  .enter-inp{
    width: 350px;
  }
  .left {
    display: none;
    /* width: 100%; */
  }
  .Enter {
    display: block;
  }
  .right {
    width: 100%;
  }
  .ver-right {
    width: 100%;
  }
  .verification-form .send-btn {
    width: 220px;
  }
  .right-bar {
    display: none;
  }
  .left_sidebar {
    display: none;
  }
  .inner__content {
    margin-left: 0;
    margin-right: 0;
  }
  
  
}