*{
    margin: 0;
    padding: 0;
}
.navbar-main{
    align-items: start;
    width: 100%;
    padding: 16px 16px;
}
.disabled-link {
    pointer-events: none;
    color: gray;
  }
  .active-link {
    color: black;

  }
.flexcolumn {
    display: flex !important;
    flex-direction: column !important;
}
.NavLink {
    font-family: 'Muli', sans-serif !important;
    font-size: 1.2rem;
    font-weight: 300;
}
.width {
    
    width: 160px !important;
    /* padding: 0 1rem 1rem 1rem !important; */
    /* margin-bottom: 2.3rem; */
    cursor: pointer !important;
}
.NavDropdowndrop a {
    font-family: 'Muli', sans-serif !important;
    font-size: 1.2rem;
    font-weight: 300;
    color: black !important;
}
.activeClass {
    font-weight: 400 !important;
    font-size: 1.3em;
    border-left: 2px solid #ccd0d4 1a !important;
    border-radius: 1px;
    color: black !important;
}
.navbartogglers {
    cursor: pointer !important;
    /* border: none !important; */
}
.navbartogglers span {
    height: 32px;
    width: 32px;
    cursor: pointer !important;
}
.spreate {
    height: 1px;
    width: 100%;
    background-color: #b1b1b1 52;
    margin: 1em 0;
}
.navbar-collapse {
 align-items: flex-start;
}

@media (min-width: 992px) {
    .min-vh-100-lg {
      min-height: 100vh;
    }
  }
  