*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.Verification {
    display: flex;
    height: 100vh;
  }
  .ver-right {
    text-align: center;
    width: 50%;
    background-color: #FFFFFF;
    padding: 0px 50px 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ver-right-title {
    font-weight: 700;
    font-size: 35px;
    color: #313131;
    margin-bottom: 30px;
  }
  .verification-img {
    margin: 0px auto 70px ;
  
  }
  .enter-text {
    font-weight: 500;
    font-size: 20px;
    color: #313131;
  }
  .enter-phone {
    margin-bottom: 50px;
    font-weight: 600;
    font-size: 20px;
    color: #86C8EF;
  }
  .enter-form {
    display: flex;
    flex-flow: column;
  }
  .enter-inp {
    width: 470px;
    height: 50px;
    background: #EDF2F7;
    border: none;
    border-radius: 5px;
    font-family: inherit;
    padding: 0.375rem 1.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  .enter-inp::placeholder {
    font-weight: 500;
    font-size: 20px;
    color: #CBCACE;
  }
  .send-btn {
    height: 50px;
    width: 470px;
    background-color: #86B6EF;
    border-radius: 5px;
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    height: 50px;
    border: none;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .send-text {
    font-weight: 500;
    font-size: 20px;
    color: #313131;
  }
  .send-link {
    text-decoration: none;
    font-weight: 600;
    font-size: 20px;
    color: #85B6EE;
  }
  