*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.Enter {
    display: flex;
    /* height: 100vh; */
  }
  
  .left {
    width: 50%;
    background: linear-gradient(27.46deg, #aec3ff 0%, #75cae8 101.76%);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
  }
  .logo-box {
    width: 100%;
    height: 93px;
  }
  .logo {
    width: 51px;
    height: 58px;
  }
  .logo-title {
    font-weight: 800;
    color: #272727;
    line-height: 31px;
    font-size: 25px;
  }
  .logo-wrapper {
    margin-top: 40px;
    margin-left: 40px;
    display: flex;
    width: 148px;
    align-items: center;
  }
  .left-title-box {
    width: 477px;
    height: 292px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: column;
    padding-left: 50px;
  }
  .left-title,
  .left-title-second,
  .left-title-third {
    font-weight: 600;
    font-size: 70px;
    color: #313131;
  }
  .welcome-box {
    width: 359px;
    height: 69px;
    text-align: center;
    margin-bottom: -140px;
  }
  .welcome {
    font-weight: 500;
    font-size: 30px;
    color: #313131;
  }
  .after-welcome {
    color: #313131;
  }
  .circle-box {
    width: 100%;
    height: 150px;
    position: relative;
    overflow: hidden;
  }
  .blue-circle {
    width: 439px;
    height: 439px;
    border-radius: 50%;
    background-color: #70ade6;
    position: absolute;
    bottom: 0px;
    z-index: -1;
    opacity: 0.6;
    left: -80px;
    bottom: -290px;
  }
  .white-circle {
    width: 400px;
    height: 400px;
    background-color: #b1c9ff;
    opacity: 0.6;
    border-radius: 50%;
    position: absolute;
    right: -10px;
    bottom: -290px;
    z-index: -2;
  }
  .right {
    width: 50%;
    height: 100vh;
    background-color: #ffffff;
    padding: 0px 50px 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .right-title {
    font-weight: 700;
    font-size: 35px;
    color: #313131;
  }
  .right-text {
    font-weight: 500;
    font-size: 20px;
    color: #313131;
    margin-bottom: 70px;
  }
  .email-text {
    font-weight: 500;
    font-size: 20px;
    color: #313131;
    margin-bottom: 15px;
  }
  .email-inp {
    margin-bottom: 40px;
  }
  .email-inp,
  .pass-inp {
    height: 50px;
    background: #edf2f7;
    border: none;
    border-radius: 5px;
    font-family: inherit;
    padding: 0.375rem 1.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
  .pass-inp {
    margin-bottom: 15px !important;
  }
  .email-inp::placeholder,
  .pass-inp::placeholder {
    font-weight: 500;
    font-size: 20px;
    color: #cbcace;
  }
  .pass-text {
    font-weight: 500;
    font-size: 20px;
    color: #313131;
    margin-bottom: 13px;
    margin-top: 30px;
  }
  .forgot-link {
    font-size: 20px;
    color: #86c8ef;
    text-decoration: none;
  }
  .forgot-link:hover {
    text-decoration: underline;
  }
  .sign-btn,
  .reg-btn {
    background: #86b6ef;
    border-radius: 5px;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    height: 50px;
    border: none;
    margin-top: 30px;
    cursor: pointer;
  }
  .sign-btn:hover,
  .reg-btn:hover {
    background: #a8c8fc;
  }
  .sign-google {
    width: 269px;
    height: 52px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #5186ec;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
    color: white;
    cursor: pointer;
    padding-right: 25px;
  }
  .google-img {
    background-color: white;
    padding: 7px;
    margin-left: -3px;
    border-radius: 5px;
  }
  .enter-form {
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  .btn-box {
    display: flex;
    flex-flow: column;
  }
  