.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: #bdbdbd;
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
  }
  .dropdown-toggle::after {
    display: none !important;
  }